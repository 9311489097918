import fetcher from '@lib/fetcher'
import customerAddressCreateMutation from '@lib/mutations/customer-address-create'
import customerAddressUpdateMutation from '@lib/mutations/customer-address-update'
import customerDefaultAddressUpdate from '@lib/mutations/customer-default-address-update'
import type { CustomerUserErrors } from '@lib/types/customer'
import type { FormAddress } from '@lib/types/profile-pages'

// eslint-disable-next-line import/prefer-default-export
export async function updateCustomerAddress(
	customerAccessToken: string,
	addressId: FormAddress['id'],
	address: Omit<FormAddress, 'id'>
): Promise<true | CustomerUserErrors> {
	const updateOrError = await fetcher({
		variables: { customerAccessToken, address, addressId },
		query: customerAddressUpdateMutation
	})

	if (updateOrError?.customerAddressUpdate?.customerUserErrors?.length) {
		const { customerUserErrors: errors } = updateOrError.customerAddressUpdate

		return errors
	}

	return true
}

export async function createCustomerAddress(
	customerAccessToken: string,
	address: Omit<FormAddress, 'id'>
): Promise<true | CustomerUserErrors> {
	const createOrError = await fetcher({
		variables: { customerAccessToken, address },
		query: customerAddressCreateMutation
	})

	if (createOrError?.customerAddressCreate?.customerUserErrors?.length) {
		const { customerUserErrors: errors } = createOrError.customerAddressCreate

		return errors
	}

	const { customerAddress } = createOrError.customerAddressCreate

	const updateDefaultAddressOrError = await updateCustomerDefaultAddress(
		customerAccessToken,
		customerAddress.id
	)

	return updateDefaultAddressOrError
}

export async function updateCustomerDefaultAddress(
	customerAccessToken: string,
	addressId: FormAddress['id']
): Promise<true | CustomerUserErrors> {
	const updateOrError = await fetcher({
		variables: { customerAccessToken, addressId },
		query: customerDefaultAddressUpdate
	})

	if (updateOrError?.customerDefaultAddressUpdate?.customerUserErrors?.length) {
		const { customerUserErrors: errors } = updateOrError.customerDefaultAddressUpdate

		return errors
	}

	return true
}
