import Image from 'next/image'
import Link from 'next/link'

import linkResolver from '@lib/prismic/link-resolver'

import RichTextSerializer from '@components/RichTextSerializer'

interface Props {
	slice: any
}

export default function SplitImageShadow({ slice }: Props) {
	const { primary } = slice || {}

	return (
		<section className="section">
			<div className="container grid mt-8 lg:mt-32 lg:grid-cols-2 max-lg:gap-y-10 lg:gap-x-40">
				{/* Image */}
				{primary.section_image?.url && (
					<div className="relative">
						<div className="-translate-x-8 -translate-y-8 lg:-translate-x-32 lg:-translate-y-36">
							<Image
								src={primary.section_image.url}
								width={primary.section_image.dimensions.width}
								height={primary.section_image.dimensions.height}
								alt={primary.section_image.alt}
								className="object-contain"
								objectPosition="left"
								quality={100}
							/>
						</div>

						{/* Image shadow */}
						<div
							className="bg-bbx-glow max-w-[348px] h-[248px] sm:h-[360px] sm:max-w-[380px] xl:max-w-[490px] w-full xl:h-[504px] absolute max-lg:right-0 lg:left-0 max-lg:bottom-0 lg:top-0 -z-10"
							aria-hidden="true"
						/>
					</div>
				)}

				<div className="flex flex-col">
					{/* Title */}
					{primary?.section_title?.[0]?.text && (
						<h2 className="text-4xl lg:text-6xl title">
							{primary.section_title[0].text}
						</h2>
					)}

					{/* Description */}
					{primary?.section_description?.[0] && (
						<div className="mt-4 lg:mt-6 max-sm:text-sm lg:text-lg">
							<RichTextSerializer content={primary.section_description} />
						</div>
					)}

					{/* CTA */}
					{primary.section_cta && (
						<Link legacyBehavior href={linkResolver(primary.section_cta)}>
							<a className="mt-10 mr-auto font-medium lg:mt-14 button button-primary lg:text-lg">
								{primary.section_cta_label}
							</a>
						</Link>
					)}
				</div>
			</div>
		</section>
	)
}
