import { adminFetcher } from '@lib/fetcher'
import getCustomerPaymentMethodsQuery from '@lib/queries/get-customer-payment-methods-query'
import { normalizePaymentMethods } from '@lib/utils/normalizers/payment-methods'

/**
 * Get customer by id with Shopify Admin API
 */
// eslint-disable-next-line import/prefer-default-export
export async function getCustomerPaymentMethods(id: string) {
	const customerQuery = await adminFetcher({
		variables: { id },
		query: getCustomerPaymentMethodsQuery
	})

	if (customerQuery.customer) {
		const { customer } = customerQuery

		return normalizePaymentMethods(customer?.paymentMethods?.nodes)
	}

	return null
}
