import { useCallback, useEffect, useMemo, useRef } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import useToast from '@context/useToast'

import CartTextNodes from '@data/i18n/cart.json'
import CommonTextNodes from '@data/i18n/common.json'
import menuData from '@data/menu.json'

import getGtmEcommerceProduct from '@lib/analytics/ecommerce'
import { gtmEvent } from '@lib/analytics/gtm'
import useRemoveItem from '@lib/cart/use-remove-item'
import useUpdateItem from '@lib/cart/use-update-item'
import { prodUrl } from '@lib/constants'
import type { CartItem as CartItemType } from '@lib/types/cart'
import { getProductBreadcrumbs } from '@lib/utils/breadcrumbs'
import { isCustomizableBoxVariant } from '@lib/utils/isSubscriptionBox'

import { ShopifyWebStorefrontClient } from './OnwardStorefrontClient'

interface Props {
	id: string
	title: string
	variant: CartItemType['merchandise']
	quantity: number
	sellingPlan?: CartItemType['sellingPlanAllocation']['sellingPlan']
	estimatedCost?: CartItemType['estimatedCost']
}

function CartItem({ id, title, variant, quantity, sellingPlan, estimatedCost }: Props) {
	// Update cart item quantity
	const updateItem = useUpdateItem()

	// Remove cart item
	const removeItem = useRemoveItem()

	// Toast hook
	const toast = useToast()

	// Checker to run only once useEffect to pop a toast when product is out of stock
	const checkedQuantity = useRef<boolean>(false)

	// Remove cart item and push data to GTM

	const removeItemFromCart = useCallback(
		(variantId: string) => {
			removeItem({ id: variantId })
			const productCollectionsPaths = variant.product.collections.nodes.map(
				(collection) => collection.handle
			)
			const breadcrumbs = getProductBreadcrumbs(productCollectionsPaths, menuData.items)

			// Analytics ***
			const gtmEcommerceProduct = getGtmEcommerceProduct({
				product: {
					id: variant?.product?.id,
					name: title,
					vendor: variant?.product?.vendor
				},
				price: estimatedCost?.totalAmount?.amount ? estimatedCost?.totalAmount?.amount : undefined,
				isSubscriptionBox: !!isCustomizableBoxVariant(variant?.id),
				productBreadcrumb: breadcrumbs
			})
			gtmEvent({
				event: 'analytics_event',
				data: {
					eventData: {
						category: 'ecommerce',
						action: 'removeFromCart'
					},
					ecommerce: {
						currencyCode: estimatedCost?.totalAmount?.currencyCode,
						remove: {
							products: [gtmEcommerceProduct]
						}
					}
				}
			})
			// Analytics ***
		},
		[estimatedCost, removeItem, title, variant]
	)

	const { handle } = variant.product

	const { subtotalAmount, totalAmount } = estimatedCost || {}

	const price = totalAmount?.amount || subtotalAmount?.amount

	const beforeDiscountPrice = useMemo(
		() =>
			subtotalAmount?.amount &&
			totalAmount?.amount &&
			Number(subtotalAmount.amount) !== Number(totalAmount.amount)
				? subtotalAmount.amount
				: undefined,
		[subtotalAmount, totalAmount]
	)

	useEffect(() => {
		if (
			!checkedQuantity.current &&
			variant?.quantityAvailable === 0 &&
			variant?.product.handle !== ShopifyWebStorefrontClient.insuranceProductKey
		) {
			removeItemFromCart(id)
			toast(CartTextNodes.removedToast.title, CartTextNodes.removedToast.text)
			checkedQuantity.current = true
		}
	}, [id, removeItemFromCart, variant, toast])

	return (
		<>
			<Link legacyBehavior href={`${prodUrl}/${handle}`}>
				<a className="relative flex-shrink-0 w-24">
					{/* Product image */}
					{variant.image?.url && (
						<Image
							src={variant.image?.url}
							alt={variant.image?.altText || variant.title}
							width={variant.image?.width}
							height={variant.image?.height}
							sizes="200px"
							objectFit="cover"
						/>
					)}
				</a>
			</Link>
			<div className="flex flex-col flex-1 ml-4 sm:ml-6">
				<div className="flex text-sm">
					{title && (
						<Link legacyBehavior href={`${prodUrl}/${variant?.product?.handle}`}>
							<a className="block text-gray-700 grow hover:text-gray-800">
								<h3 className="font-medium ">{title}</h3>
								{sellingPlan?.name && <span className="mb-2">{sellingPlan?.name}</span>}
							</a>
						</Link>
					)}

					{/* Remove item */}
					<div className="justify-items-end">
						<button
							type="button"
							className="inline-flex text-gray-500 transition-colors duration-150 ease-in-out hover:text-red-500"
							aria-label="Remove item"
							onClick={() => removeItemFromCart(id)}
						>
							<i className="fa-light fa-trash-can-xmark" />
						</button>
					</div>
				</div>

				{/* Selected options */}
				{Array.isArray(variant?.selectedOptions) && variant?.selectedOptions?.length > 0 ? (
					<div className="col-span-2 text-sm">
						{variant.selectedOptions.map(
							// eslint-disable-next-line react/no-unused-prop-types
							({ name, value }: { name: string; value: string }, i: number) =>
								name && value ? (
									<div className="flex flex-col" key={`${id}`}>
										<dl>
											{/* Name */}
											<dt className="sr-only">{name}</dt>

											{/* Value */}
											<dd
												className={`text-gray-500 ${
													i > 0 ? 'border-gray-200 border-l pl-4 ml-4' : ''
												}`}
											>
												<div>{value}</div>
											</dd>
										</dl>

										{/* Qty and +/- items */}
										<div className="flex w-full pt-6">
											<div className="self-center pr-3">Qty: {quantity}</div>

											{!sellingPlan?.name && (
												<div className=" self-center flex">
													<button
														aria-label="Increase quantity"
														onClick={() =>
															updateItem({
																id,
																variantId: variant?.id,
																quantity: quantity + 1,
																quantityAvailable: variant?.quantityAvailable
															})
														}
														disabled={
															variant.quantityAvailable
																? quantity >= variant.quantityAvailable
																: false
														}
														type="button"
														className="block px-2 py-0 mx-0.5 border rounded border-slate-200 disabled:opacity-20"
													>
														+
													</button>
													<button
														aria-label="Decrease quantity"
														onClick={() =>
															updateItem({ id, variantId: variant?.id, quantity: quantity - 1 })
														}
														type="button"
														className="block px-2 py-0 mx-0.5 border rounded border-slate-200 disabled:opacity-20"
													>
														&ndash;
													</button>
												</div>
											)}
											<div className="mx-4 border-b border-dotted grow" />
											<div className="relative">
												{/* Price */}
												{undefined !== beforeDiscountPrice && (
													<del
														className="absolute right-0 text-sm text-gray-400"
														style={{ bottom: '100%' }}
													>
														{CommonTextNodes.currency.symbol}
														{beforeDiscountPrice}
													</del>
												)}
												{undefined !== price && (
													<p className="text-sm font-medium text-gray-900">
														{CommonTextNodes.currency.symbol}
														{Number(price).toFixed(2)}
													</p>
												)}
											</div>
										</div>
									</div>
								) : null
						)}
					</div>
				) : null}
			</div>
		</>
	)
}

export default CartItem
