import Link from 'next/link'

import linkResolver from '@lib/prismic/link-resolver'

type Props = {
	link: {
		link_type: string
		type: string
		tags: string[]
		uid: string
		url?: string
		target?: string
	}
	label: string
	className: string
}

export default function PrismicLink({ link, label, className = '' }: Props) {
	// External web link
	if (link?.link_type === 'Web' && link.url && label) {
		return (
			<a
				href={link.url}
				target={link.target}
				rel={link?.target === '_blank' ? 'noopener noreferrer' : undefined}
				className={className}
			>
				{label}
			</a>
		)
	}

	// Prismic doc link
	if (link?.uid)
		return (
			<Link legacyBehavior href={linkResolver(link)}>
				<a className={className}>{label}</a>
			</Link>
		)

	return null
}
