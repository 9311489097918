import { useMemo } from 'react'

import { useSession } from 'next-auth/react'

import useSWR from 'swr'

import useCart from '@lib/cart/use-cart'
import useRechargeSession from '@lib/hooks/useRechargeSession'
import { getActiveSubscription, loginWithShopify } from '@lib/operations/recharge'
import { isCustomizableBoxVariant } from '@lib/utils/isSubscriptionBox'

type ExistingBox = {
	productId: string
	state: 'subscribed' | 'in-cart'
	cartLineId?: string
	variantId?: string
}

function useCanPurchaseSubscriptionBox() {
	const { data: session } = useSession()
	const { items } = useCart()

	// declared to force initRechargeSession
	const { revalidate: revalidateSession } = useRechargeSession()

	const {
		data: existingBoxProductId,
		isValidating: loading,
		mutate
	} = useSWR<string | null>(session ? 'subscriptionBoxPlan' : null, getSubscriptionBoxPlan)

	const existingBoxes = useMemo<ExistingBox[]>(() => {
		const conflictingBoxes: ExistingBox[] = []
		if ((!items || !items.length) && !session) {
			return []
		}

		// If a subscription box exists in the cart, return false
		const subscriptionBoxExistsInCart = items?.filter((item) =>
			isCustomizableBoxVariant(item?.merchandise.id)
		)

		// Check if the box is already in the cart
		if (subscriptionBoxExistsInCart && subscriptionBoxExistsInCart?.length > 0) {
			conflictingBoxes.push(
				...subscriptionBoxExistsInCart.map((item) => ({
					productId: item.merchandise.product.id,
					variantId: item.merchandise.id,
					state: 'in-cart' as const,
					cartLineId: item?.id
				}))
			)
		}

		if (existingBoxProductId) {
			conflictingBoxes.push({ productId: existingBoxProductId as string, state: 'subscribed' })
		}

		// Mutate array so setExistingBoxes triggers a rerender (otherwise they point to the same value in memory)
		return [...conflictingBoxes]
	}, [items, existingBoxProductId, session])

	async function getSubscriptionBoxPlan(): Promise<string | null> {
		const accessToken = (session as any)?.shopifyAccessToken as string | undefined
		const rechargeSession = await loginWithShopify(accessToken)
		// Check if the user has existing boxes
		const boxContract =
			// hardcoded to let revalidate accept session as parameter
			rechargeSession ? await getActiveSubscription(rechargeSession) : null

		if (boxContract) {
			const boxContractProductId = boxContract.external_product_id.ecommerce
			return boxContractProductId
		}

		return null
	}

	return {
		// Can purchase subscription box if there are none that are bought
		canPurchaseSubscriptionBox: !existingBoxes.filter((box) => box.state === 'subscribed').length,
		canAddSubscriptionBoxToCart: !existingBoxes.length,
		subscriptionBoxInCart: existingBoxes.find((box) => box.state === 'in-cart'),
		alreadyPurchasedSubscriptionBox: existingBoxes.find((box) => box.state === 'subscribed'),
		existingBoxes,
		loading,
		revalidate: mutate
	}
}

export default useCanPurchaseSubscriptionBox
